<template>
  <v-container>
    <v-card class="bg-card rounded-xl py-5 mt-2">
      <h4 class="text-center text-h4 mb-2">Modificar paciente</h4>

      <v-row class="mx-5 mx-lg-16 px-lg-16" justify="center">
        <v-col cols="12" md="">
          <v-form ref="form" v-model="valid" lazy-validation>
            <h6 class="text-center text-h6 mb-3">Autenticación</h6>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Cédula</label
            >
            <div class="d-flex">
              <div style="width: 70px">
                <v-select
                  :items="idTypes"
                  :rules="[rules.required]"
                  disabled
                  solo
                  class="mt-2 mb-n3 rounded-l-xl"
                  v-model="patient.idType"
                >
                </v-select>
              </div>
              <v-text-field
                placeholder="Cédula"
                color="black"
                disabled
                :rules="[rules.required, rules.number]"
                v-model="patient.idNumber"
                required
                solo
                class="mt-2 mb-n3 rounded-r-xl font-quicksand"
              ></v-text-field>
            </div>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Correo electrónico</label
            >
            <v-text-field
              placeholder="Correo electrónico"
              color="black"
              rounded
              disabled
              v-model="patient.email"
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Teléfono</label
            >
            <v-text-field
              placeholder="Teléfono"
              color="black"
              rounded
              disabled
              v-model="patient.cellphone"
              solo
              class="mt-2 mb-n3 font-quicksand"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Fecha de nacimiento</label
            >

            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="patient.birthday"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="birthday"
                  v-model="patient.birthday"
                  solo
                  rounded
                  placeholder="Fecha de nacimiento"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="font-quicksand mb-n3"
                ></v-text-field>
              </template>
              <v-date-picker v-model="patient.birthday" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(patient.birthday)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-form>
        </v-col>
        <v-divider vertical class="my-8 mx-lg-16 d-md-block d-none"></v-divider>

        <v-col cols="12" md="">
          <v-form ref="form2" v-model="valid" lazy-validation>
            <h6 class="text-center text-h6 mb-3">Datos personales</h6>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Nombres</label
            >
            <v-text-field
              placeholder="Nombres"
              color="black"
              rounded
              v-model="patient.firstName"
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Apellidos</label
            >
            <v-text-field
              placeholder="Apellidos"
              color="black"
              rounded
              v-model="patient.lastName"
              solo
              class="mt-2 mb-n3"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Genero</label
            >
            <v-select
              :items="sexs"
              placeholder="Seleccione el genero"
              v-model="patient.gender"
              solo
              rounded
              class="mt-2 mb-n3"
            ></v-select>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Dirección</label
            >
            <v-textarea
              placeholder="Dirección"
              color="black"
              rounded
              v-model="patient.address"
              solo
              rows="2"
              class="mt-2 mb-n3"
            ></v-textarea>
          </v-form>
        </v-col>
      </v-row>

      <div
        class="d-flex flex-column flex-md-row align-center justify-end mx-md-16"
      >
        <router-link custom v-slot="{ navigate }" :to="{ name: 'Patient' }">
          <v-btn
            color="secondary"
            outlined
            class="rounded-lg mr-md-4 my-2 my-md-0"
            @click="navigate"
          >
            <v-icon>fa-times</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
          </v-btn>
        </router-link>

        <v-btn
          color="primary"
          class="rounded-lg ml-md-4 my-2 my-md-0 elevation-0"
          @click="submit"
          :loading="loading"
        >
          <v-icon>fa-user-edit</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
        </v-btn>
      </div>
    </v-card>

    <dialog-edit
      :item="item"
      :dialog="dialogEdit"
      @dismiss="
        () => {
          dialogEdit = false
        }
      "
      @accept="update()"
    />
  </v-container>
</template>

<script>
import { format } from 'date-fns'
import Swal from 'sweetalert2'
import Repository from '@/repositories/RepositoryFactory'
import DialogEdit from '@/components/DialogEdit.vue'
const PatientRepository = Repository.get('patients')

export default {
  components: { DialogEdit },
  name: 'EditPatient',
  props: ['id'],
  data: () => ({
    valid: false,
    show: false,
    menu: false,
    loading: false,
    dialogEdit: false,
    item: {},
    patient: {
      birthday: '1-1-1970',
    },
    idTypes: ['V', 'J', 'E'],
    sexs: [
      {
        value: 'F',
        text: 'Femenino',
      },
      {
        value: 'M',
        text: 'Masculino',
      },
      {
        value: 'O',
        text: 'Otro',
      },
    ],
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      counter: (value) =>
        value?.length >= 8 || 'Contraseña mínima de 8 carácteres.',
      email: (value) => {
        const pattern = /.+@.+/
        return pattern.test(value) || 'Email inválido.'
      },
      number: (value) => {
        const pattern = /[^0-9]/
        return !pattern.test(value) || 'Campo inválido.'
      },
      phone: (value) => {
        const pattern = /[^0-9][^0-9]+[-]?$/
        return !pattern.test(value) || 'Campo inválido.'
      },
    },
  }),
  created: async function () {
    await this.loadData()
  },
  methods: {
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = await PatientRepository.getPatient(this.id)
        this.patient = res.data.data

        this.patient.birthday = format(
          new Date(this.patient.birthday),
          'yyyy-MM-dd'
        )
        // this.patient.birthday = '1970-01-02'
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.error : error

        console.log(response)
      }
    },

    submit: async function () {
      this.$refs.form.validate()
      this.$refs.form2.validate()

      setTimeout(async () => {
        if (this.valid) {
          this.item = {
            type: 'paciente',
            id: this.patient.idNumber,
            name: `${this.patient.firstName} ${this.patient.lastName}`,
          }
          this.dialogEdit = true
        }
      }, 500)
    },

    update: async function () {
      this.dialogEdit = false
      this.loading = true

      try {
        const date = new Date(this.patient.birthday)
        date.setDate(date.getDate() + 1)

        this.patient.birthday = format(date, 'MM-dd-yyyy')

        const res = await PatientRepository.update(this.patient, this.id)

        const data = {
          type: 'success',
          title: 'Petición exitosa',
          text: res.data.message,
        }

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$router.back()
      } catch (error) {
        const response = error.response
          ? error.response.data.message ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }
      this.loading = false
    },
  },
}
</script>
